/* General styles */
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .home-container {
      scroll-snap-type: none;
    }

    .home-section {
      scroll-snap-align: none;
    }
  }

  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  /* Hero section */
  .hero {
    @apply bg-gray-100 py-12 text-center rounded-lg;
  }
  
  .hero h1 {
    @apply text-3xl mb-5;
  }
  
  /* Add this new class for the button container */
  .hero-buttons {
    @apply flex justify-center gap-5 mt-5;
  }
  
  /* About Me section */
  .about-me {
    @apply py-12;
  }
  
  .about-me h2 {
    @apply text-2xl mb-5;
  }
  
  /* Skills & Expertise section */
  .skills {
    @apply bg-gray-100 py-12;
  }
  
  .skills h2 {
    @apply text-2xl mb-5;
  }
  
  .skills ul {
    @apply list-none p-0;
  }
  
  .skills li {
    @apply mb-2;
  }
  
  /* Recent Work or Featured Projects section */
  .projects {
    @apply py-12;
  }
  
  .projects h2 {
    @apply text-2xl mb-5;
  }
  
  .projects ul {
    @apply list-none p-0;
  }
  
  .projects li {
    @apply mb-2;
  }
  
  /* Call to Action section */
  .cta {
    @apply bg-gray-100 py-12 text-center;
  }
