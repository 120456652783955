:root {
  --font-family-sans: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  --font-family-mono: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  --color-beige: #F5F2EB;
  --color-white: #FFFFFF;
  --color-charcoal: #333333;
  --color-gray: #666666;
  --color-pastel-green: #A8C69F;
  --color-gold: #D4AF37;
  --color-light-gray: #E0E0E0;
}

body {
  margin: 0;
  font-family: var(--font-family-sans);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-beige);
  color: var(--color-charcoal);
}

code {
  font-family: var(--font-family-mono);
}
